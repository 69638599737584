import { render, staticRenderFns } from "./contactUs.vue?vue&type=template&id=4e2aee1c&"
import script from "./contactUs.vue?vue&type=script&lang=js&"
export * from "./contactUs.vue?vue&type=script&lang=js&"
import style0 from "./contactUs.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCardText,VCol,VDialog,VRow,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4e2aee1c')) {
      api.createRecord('4e2aee1c', component.options)
    } else {
      api.reload('4e2aee1c', component.options)
    }
    module.hot.accept("./contactUs.vue?vue&type=template&id=4e2aee1c&", function () {
      api.rerender('4e2aee1c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/contactUs.vue"
export default component.exports