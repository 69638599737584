<template>
  <v-card elevation="0">
    <v-card-text>
      <v-row justify="end" v-if="updatedMessage">
        <v-col cols="12" md="6" lg="6" xl="6" sm="12">
          <v-alert
            v-if="updatedMessage"
            v-model="updatedMessage"
            max-height="250"
            dense
            dismissible
            text
            :class="
              $vuetify.breakpoint.xsOnly ? 'text-caption' : 'text-subtitle-2'
            "
            class="px-1 ma-0 pr-2"
            icon="mdi-check-circle-outline"
            color="green darken-4"
          >
            Plan Update Successfully!
          </v-alert>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
          <v-card>
            <v-card-title class="px-7">
              <v-row>
                <v-col cols="6"> Service Plan </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <v-chip
                    small
                    :color="
                      showPendingTag
                        ? getColor('pending')
                        : getColor(companyInfo.subscription.status)
                    "
                    class="white--text mr-3"
                  >
                    {{
                      showPendingTag
                        ? $t("pending")
                        : companyInfo.subscription.status === "active"
                        ? $t("subscribed")
                        : $t(companyInfo.subscription.status)
                    }}</v-chip
                  >
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row no-gutters class="my-4 px-3">
                <span class="my-auto">{{ $t("plan") }} </span>
                <v-spacer></v-spacer>
                <v-col cols="6" md="5" lg="5" class="d-flex justify-end">
                  <v-select
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-8' : 'ml-4'"
                    v-model="selectedSubscription"
                    :items="subscriptions"
                    label=""
                    item-text="sub_name"
                    :menu-props="{ bottom: true, offsetY: true }"
                    dense
                    hide-details
                    outlined
                    item-disabled="3 > item.subscription_rank"
                    return-object
                    @change="changePlan"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3"
                ><span class="my-auto">Choose Your Team Size </span
                ><v-tooltip
                  :right="!$vuetify.breakpoint.smAndDown"
                  :bottom="$vuetify.breakpoint.smAndDown"
                  max-width="250"
                  class="pa-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >maximum of 14 users may be selected, if they want to add
                    more users they may do so after the company is created from
                    the company settings screen</span
                  > </v-tooltip
                ><v-spacer></v-spacer>
                <v-col cols="4" sm="4" md="5" lg="5" xl="5" class="ml-4">
                  <v-select
                    v-model="users"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-8' : 'ml-4'"
                    :items="usersList"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    required
                    dense
                    hide-details
                    label="users"
                    @change="changeUsers"
                  ></v-select>
                </v-col>
              </v-row>
              <v-card-title class="grey--text text--darken-4">
                Additional Services
              </v-card-title>
              <div>
                <v-row class="mb-5" justify="end">
                  <v-card
                    :width="$vuetify.breakpoint.mdAndUp ? '98%' : '98%'"
                    elevation="0"
                    class="excavator-type-style mt-6"
                    color="#FAFAFA"
                    :disabled="
                      selectedSubscription &&
                      selectedSubscription.price == 0 &&
                      !selectedSubscription.is_custom &&
                      companyCenters.length >= 1
                    "
                  >
                    <v-card-title class="font-weight-medium body-2">
                      {{ $t("add811Center") }}
                    </v-card-title>
                    <v-container class="px-0">
                      <v-row no-gutters>
                        <v-col cols="12" lg="6" md="6" class="px-3">
                          <v-select
                            v-model="selectedCenter"
                            :items="select811CenterOptions"
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            required
                            :label="$t('811Center')"
                            item-text="center_name"
                            item-value="center_id"
                            :disabled="
                              selectedSubscription &&
                              selectedSubscription.price == 0 &&
                              !selectedSubscription.is_custom &&
                              companyCenters.length >= 1
                            "
                            @change="onCenterChange"
                            @blur="onCenterChange"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" class="px-3">
                          <v-select
                            v-model="excavatorType"
                            :items="excavatorTypes"
                            :disabled="disableExcavatorTypeSelection"
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            required
                            :label="$t('excavatorType')"
                            item-value="text"
                          ></v-select>
                        </v-col>
                        <v-row no-gutters class="ml-3" justify="end">
                          <v-btn
                            class="mr-4 text-none rounded-lg"
                            color="primary"
                            :disabled="!selectedCenter || !excavatorType"
                            @click="addCenter"
                          >
                            Add
                          </v-btn>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-row>
                <v-row
                  v-if="
                    $vuetify.breakpoint.mdAndUp && companyCenters.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    xl="4"
                    md="4"
                    lg="4"
                    class="pb-0 caption font-weight-medium grey--text text--darken-1"
                    :class="$vuetify.breakpoint.smAndDown ? 'pl-0' : 'pl-7'"
                    >{{ $t("811Center") }}</v-col
                  >
                  <v-col
                    cols="12"
                    xl="4"
                    md="4"
                    lg="4"
                    class="pb-0 caption font-weight-medium grey--text text--darken-1"
                    :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : null"
                    >{{ $t("excavatorType") }}</v-col
                  >
                  <v-col
                    cols="12"
                    xl="4"
                    md="4"
                    lg="4"
                    class="text-center pb-0 caption font-weight-medium grey--text text--darken-1"
                    :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : null"
                    >{{ $t("delete") }}</v-col
                  >
                </v-row>
                <v-row
                  v-if="
                    $vuetify.breakpoint.mdAndUp && companyCenters.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    xl="12"
                    md="12"
                    lg="12"
                    :class="$vuetify.breakpoint.smAndDown ? 'px-0' : null"
                    ><v-divider></v-divider></v-col
                ></v-row>
                <div v-for="(value, index) in companyCenters" :key="index">
                  <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mx-0">
                    <v-col
                      cols="12"
                      xl="4"
                      md="4"
                      lg="4"
                      class="pb-0 caption pl-4"
                    >
                      {{ value.center_name }}
                    </v-col>
                    <v-col
                      cols="12"
                      xl="4"
                      md="4"
                      lg="4"
                      class="pb-0 caption pl-2"
                      >{{ value.excavator_type }}</v-col
                    >
                    <v-col
                      cols="12"
                      xl="4"
                      md="4"
                      lg="4"
                      class="pb-0 text-center caption pl-2"
                    >
                      <v-btn
                        icon
                        aria-label="delete"
                        :disabled="
                          centerCount >= index + 1 &&
                          companyUserRole !== 'sysop'
                        "
                        @click="deleteCenter(value)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="$vuetify.breakpoint.smAndDown"
                    class="mx-0 px-0 mt-3"
                  >
                    <v-col cols="10">
                      <v-row
                        class="pb-0 caption grey--text text--darken-4 px-0"
                      >
                        {{ value.center_name }}
                      </v-row>
                      <v-row
                        class="pb-0 caption pa-0 grey--text text--darken-4"
                        >{{ value.excavator_type }}</v-row
                      >
                    </v-col>
                    <v-col
                      cols="2"
                      class="pb-0 text-center caption pa-0 grey--text text--darken-4"
                    >
                      <v-btn
                        icon
                        aria-label="delete"
                        @click="deleteCenter(value)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0 px-0">
                    <v-col
                      cols="12"
                      xl="12"
                      md="12"
                      lg="12"
                      class="mx-0 pl-0"
                      :class="$vuetify.breakpoint.smAndDown ? 'px-0' : null"
                      ><v-divider></v-divider></v-col
                  ></v-row>
                </div>
              </div>
              <v-row
                v-if="
                  selectedSubscription &&
                  (selectedSubscription.price > 0 ||
                    (!selectedSubscription.is_custom &&
                      selectedSubscription.tier !== '0'))
                "
                class="mt-2"
                :class="$vuetify.breakpoint.mdAndUp ? 'pl-6 pr-4' : 'pl-4 pr-2'"
              >
                <v-card elevation="0" width="80">
                  <span
                    class="caption grey--text text--darken-1 my-auto pa-0"
                    >{{ $t("additionalAttachmentsStorage") }}</span
                  >
                </v-card>
                <v-spacer></v-spacer>
                <v-col cols="6" md="5" lg="5">
                  <v-select
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-0'"
                    v-model="planStorage"
                    :items="storage"
                    :placeholder="
                      planStorage && Object.keys(planStorage).length > 0
                        ? ''
                        : 'Select'
                    "
                    item-text="storage_limit"
                    :menu-props="{ bottom: true, offsetY: true }"
                    dense
                    hide-details
                    outlined
                    return-object
                    @change="changeAttachment"
                  >
                    <template slot="item" slot-scope="data">
                      {{ getStorageText(data.item.storage_limit) }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ getStorageText(item.storage_limit) }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-card-title
                class="grey--text text--darken-4 mt-4"
                v-show="selectedSubscription && selectedSubscription.price > 0"
              >
                Payment
              </v-card-title>
              <v-row
                no-gutters
                class="mt-0"
                v-show="selectedSubscription && selectedSubscription.price > 0"
              >
                <v-col cols="12" class="pa-0">
                  <v-radio-group v-model="payment_method" class="mt-0" row>
                    <v-card
                      height="100%"
                      width="100%"
                      elevation="0"
                      rounded="lg"
                      outlined
                    >
                      <v-card-title>
                        <v-radio value="creditCard"></v-radio> Credit Card
                      </v-card-title>
                      <v-card-text>
                        <v-row
                          class="pb-8"
                          justify="end"
                          v-if="
                            paymentMethods &&
                            paymentMethods.exp_month &&
                            !editPaymantMethod
                          "
                        >
                          <v-col cols="10">
                            xxxxxxxxxxxx{{ paymentMethods.last4 }}
                            {{ paymentMethods.exp_month }}/{{
                              paymentMethods.exp_year
                            }}
                          </v-col>
                          <v-col cols="1">
                            <v-icon
                              class="mr-2"
                              aria-label="Edit"
                              @click="onEditPaymantMethod"
                            >
                              mdi-pencil-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                        <v-card
                          height="100%"
                          width="100%"
                          elevation="0"
                          rounded="lg"
                          outlined
                          v-show="
                            !paymentMethods ||
                            Object.keys(paymentMethods).length == 0 ||
                            editPaymantMethod
                          "
                        >
                          <div id="card-element" />
                        </v-card>
                        <v-row
                          class="mt-2"
                          justify="end"
                          v-if="
                            paymentMethods &&
                            paymentMethods.exp_month &&
                            editPaymantMethod
                          "
                        >
                          <v-btn
                            class="text-none"
                            color="primary"
                            outlined
                            @click="onCancel"
                          >
                            Cancel
                          </v-btn>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- <v-card
                      height="100%"
                      width="100%"
                      elevation="0"
                      rounded="lg"
                      class="mt-2"
                      outlined
                    >
                      <v-card-title>
                        <v-radio value="invoice"></v-radio>
                        Invoice
                      </v-card-title>
                    </v-card> -->
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
          :class="$vuetify.breakpoint.smAndDown ? 'pl-0' : 'pl-4'"
        >
          <v-card>
            <v-card-title> Order Summary </v-card-title>
            <v-card-text>
              <v-row no-gutters class="text-subtitle-1 font-weight-medium">
                Subscription
              </v-row>
              <v-row no-gutters>
                <v-col cols="6"> Plan Cost </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  ${{
                    selectedSubscription.base_price
                      ? selectedSubscription.base_price
                      : selectedSubscription.is_custom
                      ? stripeSubcription.plan && stripeSubcription.plan.amount
                        ? stripeSubcription.plan.amount / 100
                        : 0
                      : 0
                  }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  {{ users }} users x ${{
                    selectedSubscription.price ? selectedSubscription.price : 0
                  }}
                  x 12 months
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  ${{
                    selectedSubscription.price
                      ? selectedSubscription.price * 12 * users
                      : 0
                  }}
                </v-col>
              </v-row>
              <v-row no-gutters class="text-subtitle-1 font-weight-medium mt-2">
                Centers
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  {{
                    companyCenters.length == 0 ? 0 : companyCenters.length - 1
                  }}
                  centers x ${{
                    selectedSubscription.center_stripe_unit_amount / 12
                      ? selectedSubscription.center_stripe_unit_amount / 12
                      : 0
                  }}x 12 months
                  <v-tooltip
                    :right="!$vuetify.breakpoint.smAndDown"
                    :bottom="$vuetify.breakpoint.smAndDown"
                    max-width="250"
                    class="pa-0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      You can add one center for free. Additional centers are
                      available for a nominal price.
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  ${{
                    selectedSubscription.center_stripe_unit_amount
                      ? selectedSubscription.center_stripe_unit_amount *
                        (companyCenters.length > 0
                          ? companyCenters.length - 1
                          : 0)
                      : 0
                  }}
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="text-subtitle-1 font-weight-medium mt-2"
                v-if="
                  planStorage &&
                  Object.keys(planStorage).length > 0 &&
                  selectedSubscription.price > 0
                "
              >
                Attachments
              </v-row>
              <v-row
                no-gutters
                v-if="
                  planStorage &&
                  Object.keys(planStorage).length > 0 &&
                  selectedSubscription.price > 0
                "
              >
                <v-col cols="6">
                  {{ getStorageText(planStorage.storage_limit) }}
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  ${{ planStorage.price / 100 }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-divider></v-divider>
              </v-row>
              <v-row no-gutters class="my-4">
                <v-col cols="6" class="text-subtitle-1 font-weight-medium">
                  Total Due Today
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  ${{ amountDue }}
                </v-col>
              </v-row>
              <v-row no-gutters class="text-subtitle-1 font-weight-medium">
                Your subscription will renew annually, and you'll be charged
                this amount unless you make changes or cancel
              </v-row>
              <v-row no-gutters class="my-4">
                <v-col cols="6" class="text-subtitle-1 font-weight-medium">
                  Plan end date
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  {{ planEndDate }}
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-4 mb-8">
                <v-btn
                  v-if="
                    (selectedSubscription.is_custom && tier == '3') ||
                    (companyUserRole == 'sysop' &&
                      selectedSubscription.is_custom)
                  "
                  block
                  color="primary"
                  class="text-none rounded-xl"
                  @click="submit"
                  :disabled="isDisabled"
                >
                  Update
                  <v-progress-circular
                    v-if="updating"
                    indeterminate
                    size="20"
                    color="white"
                    class="ml-2"
                  ></v-progress-circular>
                </v-btn>
                <v-btn
                  v-if="!selectedSubscription.is_custom"
                  block
                  color="primary"
                  class="text-none rounded-xl"
                  @click="submit"
                  :disabled="isDisabled"
                >
                  Purchase
                  <v-progress-circular
                    v-if="updating"
                    indeterminate
                    size="20"
                    color="white"
                    class="ml-2"
                  ></v-progress-circular>
                </v-btn>
                <v-btn
                  v-if="
                    selectedSubscription.is_custom &&
                    tier !== '3' &&
                    companyUserRole !== 'sysop'
                  "
                  block
                  color="primary"
                  class="text-none rounded-xl"
                  :disabled="
                    companyCenters.length == 0 ||
                    disableContactUs ||
                    companyInfo.subscription.status === 'pending'
                  "
                  @click="openContactUsDialog"
                >
                  Contact us
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="isDownGradePlan" max-width="400" persistent>
      <v-card width="400" class="oveflow-hidden pb-3">
        <v-card-title class="pr-2">
          <v-row no-gutters>
            <span class="my-auto"> {{ $t("planChange") }}</span>
            <v-col class="d-flex justify-end">
              <v-btn
                aria-label="cross"
                icon
                width="50"
                @click="changePlanDialog"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-subtitle-1 grey--text text--darken-4 mt-3">
          {{ $t("downGradeMessage")
          }}<a class="primary--text ml-1 my-auto">sales@norfielddp.com</a>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row justify="end">
            <v-btn
              class="mt-4 rounded-lg"
              aria-label="cross"
              color="primary"
              @click="changePlanDialog"
            >
              {{ $t("dismiss") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Contactus
      v-if="contactUsdialog"
      :company-information="companyInfo"
      :dialog="contactUsdialog"
      @closeDialog="closeDialog"
      @sentQuote="sentQuote"
      @changeContactUs="changeContactUs"
    />
    <v-dialog v-model="isEmailSent" width="35%"
      ><v-card height="50%">
        <ConfirmationMessage
          v-if="isEmailSent"
          :icon="emailSentObject.icon"
          :text="emailSentObject.text"
          :button="emailSentObject.button"
          :show-logo="emailSentObject.showLogo"
          :background-color="emailSentObject.backgroundColor"
          @onBackToTicket="onBackToSubscription" /></v-card
    ></v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import UserData from "../../mixins/UserData.vue";
import { loadStripe } from "@stripe/stripe-js";
import Contactus from "./contactUs.vue";
import ConfirmationMessage from "./ConfirmationMessage.vue";

export default {
  components: { Contactus, ConfirmationMessage },
  mixins: [UserData],
  props: {
    companyInfo: {
      type: Object,
      default: () => {},
    },
    paymentHistory: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      //loader Added
      updating: false,
      isCreditCardDetailsValid: false,
      isEmailSent: false,
      showPendingTag: false,
      tier: "",
      emailSentObject: {
        text: {
          bodyLabel: "Your request has been sent successfully to <strong>sales@norfield.com</strong>. Our team will get back to you very soon.",
          headerLabel: "Email Sent!",
          bodyLabelParams: {
            email: "",
          },
        },
        button: {
          buttonLabel: "done",
          buttonColor: "#0073D1",
          buttonOutlined: false,
          buttonEvent: "onBackToTicket",
        },
        icon: {
          iconName: "",
          iconColor: "",
          isIcon: false,
        },
        showLogo: false,
        backgroundColor: false,
      },
      contactUsdialog: false,
      centerCount: 0,
      isDownGradePlan: false,
      updatedMessage: false,
      editPaymantMethod: false,
      subscriptionItem: [],
      centerItem: [],
      attachmentItem: [],
      amountDue: 0,
      stripeSubcription: {},
      paymentMethods: {},
      stripe: {},
      elements: {},
      paymentElement: "",
      onAddCenter: true,
      selectedCenter: null,
      excavatorType: null,
      companyCenters: [],
      planStorage: {},
      payment_method: "creditCard",
      storage: [],
      disableContactUs: false,
      users: 1,
      usersList: Array(14)
        .fill(1)
        .map((x, y) => x + y),
      selectedSubscription: "",
      clientSecret: "",
    };
  },
  async mounted() {
    const publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    this.stripe = await loadStripe(publishableKey);
    const { clientSecret } = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/company/setup-intent`
    ).then((res) => res.json());

    const style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "18px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    this.clientSecret = clientSecret;
    if (parseInt(this.selectedSubscription.price) > 0) {
      this.elements = this.stripe.elements({ clientSecret });

      this.paymentElement = this.elements.create("card", { style: style });
      this.paymentElement.mount("#card-element");
      this.paymentElement.on("change", (event) => {
        const { complete, empty } = event;
        if (this.payment_method === "creditCard")
          this.isCreditCardDetailsValid = complete && !empty;
      });
    }
  },
  computed: {
    isDisabled() {
      const {
        companyCenters,
        updating,
        payment_method,
        isCreditCardDetailsValid,
        purchasePriceCheck,
      } = this;

      if (
        this.selectedSubscription.price != 0 &&
        payment_method === "creditCard" &&
        (!this.paymentMethods ||
          Object.keys(this.paymentMethods).length == 0 ||
          this.editPaymantMethod)
      ) {
        return payment_method === "creditCard" && !isCreditCardDetailsValid;
      } else
        return companyCenters.length === 0 || updating || !purchasePriceCheck;
    },
    purchasePriceCheck() {
      return (
        this.centerCount != this.companyCenters.length ||
        this.users != parseInt(this.companyInfo.users) ||
        this.payment_method != this.companyInfo.payment_method ||
        (this.planStorage &&
          parseInt(this.companyInfo.storage) <
            parseInt(this.planStorage.storage_limit)) ||
        this.selectedSubscription.subscription_id !==
          this.companyInfo?.subscription_id
      );
    },
    priceMap() {
      let pricing = {};
      if (this.$store.state.Subscription.subscriptionProducts.prices)
        this.$store.state.Subscription.subscriptionProducts.prices.forEach(
          (element) => {
            pricing[element.product] = element;
          }
        );
      return pricing;
    },
    companyUserRole() {
      return this.$store.state.User.user.role[0];
    },
    subscriptions() {
      if (this.$store.state.Subscription.subscriptionProducts.subscriptions)
        this.$store.state.Subscription.subscriptionProducts.subscriptions.forEach(
          (element) => {
            let center;
            if (element.products)
              center =
                this.$store.state.Subscription.subscriptionProducts.prices.find(
                  (item) => item.product === element.products.center
                );
            if (center) {
              element.center_stripe_price_id = center.id;
              element.center_stripe_unit_amount = center.unit_amount / 100;
            }
            let baseProduct;
            if (element.products)
              baseProduct =
                this.$store.state.Subscription.subscriptionProducts.prices.find(
                  (item) => item.product === element.products.baseProduct
                );
            if (baseProduct) {
              element.stripe_price_id = baseProduct.id;
              element.base_price = baseProduct?.tiers[0].flat_amount / 100;
              element.price = baseProduct?.tiers[0].unit_amount / 100 / 12;
            }
          }
        );
      return this.$store.state.Subscription.subscriptionProducts.subscriptions
        ? this.$store.state.Subscription.subscriptionProducts?.subscriptions
        : [];
    },
    planEndDate() {
      const today = new Date(this.companyInfo.subscription_started_at);
      const nextYear = new Date(today);
      nextYear.setFullYear(today.getFullYear() + 1);
      const formattedDate = `${(nextYear.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${nextYear
        .getDate()
        .toString()
        .padStart(2, "0")}/${nextYear.getFullYear()}`;
      return formattedDate;
    },
    centerData() {
      return this.$store.state.User.centers;
    },
    select811CenterOptions() {
      return this.centerData.map(({ center_id, center_name }, index) => {
        if (
          !this.companyCenters.find(
            (element) => element.center_id === center_id
          )
        )
          return { center_id, center_name };
      });
    },
    excavatorTypes() {
      if (this.selectedCenter) {
        let tempCallerTypes = this.centerData.filter((element, index) => {
          return element["center_id"] === this.selectedCenter;
        });
        return tempCallerTypes[0]["caller_types"];
      } else {
        return [];
      }
    },
    disableExcavatorTypeSelection() {
      return this.excavatorTypes.length === 0;
    },
    centerMap() {
      return this.$store.getters.centerMap;
    },
  },
  watch: {
    updating() {
      setTimeout(() => {
        this.updating = false;
      }, 10000);
    },
    selectedSubscription() {
      const style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "20px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
      if (!this.selectedSubscription) this.selectedSubscription = {};
      if (this.selectedSubscription.is_custom)
        this.usersList = Array(50)
          .fill(1)
          .map((x, y) => x + y);
      else {
        this.usersList = Array(14)
          .fill(1)
          .map((x, y) => x + y);
        if (this.users > 14 && parseInt(this.companyInfo.users) < 15)
          this.users = parseInt(this.companyInfo.users);
        else if (this.users > 14) this.users = 14;
      }
      if (
        this.selectedSubscription &&
        parseInt(this.selectedSubscription.price) === 0 &&
        this.paymentElement
      )
        this.$nextTick(() => {
          this.paymentElement.unmount("#card-element");
        });
      else if (
        this.selectedSubscription &&
        parseInt(this.selectedSubscription.price) !== 0 &&
        Object.keys(this.stripe).length > 0
      ) {
        const clientSecret = this.clientSecret;
        this.elements = this.stripe.elements({ clientSecret });
        this.paymentElement = this.elements.create("card", { style: style });
        this.paymentElement.mount("#card-element");
        this.paymentElement.on("change", (event) => {
          const { complete, empty } = event;
          if (this.payment_method === "creditCard")
            this.isCreditCardDetailsValid = complete && !empty;
        });
      }
    },
    subscriptions() {
      this.getStripeSubscriptionData();
    },
    editPaymantMethod() {
      const style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "20px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
      if (this.editPaymantMethod) {
        const clientSecret = this.clientSecret;
        this.elements = this.stripe.elements({ clientSecret });
        this.paymentElement = this.elements.create("card", { style: style });
        this.paymentElement.mount("#card-element");
        this.paymentElement.on("change", (event) => {
          const { complete, empty } = event;
          if (this.payment_method === "creditCard")
            this.isCreditCardDetailsValid = complete && !empty;
        });
      }
    },
  },
  async created() {
    if (this.$store.state.User.centers.length === 0) {
      this.fetchCentersData();
    }
    await this.getStripeSubscriptionData();
  },
  methods: {
    changeContactUs(payload) {
      this.disableContactUs = payload.disableContactUs;
      this.showPendingTag = true;
    },
    closeDialog() {
      this.contactUsdialog = false;
    },
    sentQuote() {
      this.isEmailSent = true;
      this.contactUsdialog = false;
    },
    onBackToSubscription() {
      this.isEmailSent = false;
    },
    openContactUsDialog() {
      this.contactUsdialog = true;
    },
    async getStripeSubscriptionData() {
      this.tier = this.companyInfo.tier_count;
      if (this.companyInfo.stripe_subscription_id) {
        const SubscriptionStatus = await this.$store.dispatch(
          "getStripeSubscription",
          this.companyInfo
        );
        if (SubscriptionStatus.subscription) {
          this.stripeSubcription = SubscriptionStatus.subscription;
          this.paymentMethods =
            this.companyInfo.payment_method === "creditCard"
              ? SubscriptionStatus.paymentMethods?.data[0]?.card
              : {};
        }
      }
      this.subscriptionItem = [];
      this.centerItem = [];
      this.attachmentItem = [];
      this.users = parseInt(this.companyInfo.users);
      this.centerCount = this.companyInfo.centers.length;
      this.payment_method = this.companyInfo.payment_method;
      this.companyCenters = JSON.parse(
        JSON.stringify(this.companyInfo.centers)
      );
      if (this.companyInfo.subscription_id && this.subscriptions.length > 0) {
        let subscription = this.subscriptions.filter((element) => {
          return (
            element.subscription_id === this.companyInfo?.subscription_id &&
            this.companyInfo.subscription.status != "pending"
          );
        });
        this.selectedSubscription = subscription[0];
        if (this.selectedSubscription?.products.storage)
          this.storage = this.selectedSubscription.products.storage.map(
            (element) => {
              element.stripe_price_id = this.priceMap[element.id]?.id;
              element.price = this.priceMap[element.id]?.unit_amount;
              return element;
            }
          );
        if (!this.selectedSubscription) {
          this.selectedSubscription =
            this.$store.state.Subscription.subscriptionProducts.subscriptions[
              this.$store.state.Subscription.subscriptionProducts.subscriptions
                .length - 1
            ];
        }
      }
      const items = this.stripeSubcription.items?.data || [];
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        let itemObj = { id: element.id };
        if (this.selectedSubscription.stripe_price_id === element.price.id) {
          itemObj.quantity = element.quantity;
          itemObj.stripe_price_id = this.selectedSubscription.stripe_price_id;
          this.subscriptionItem.push(itemObj);
        } else if (
          this.selectedSubscription.center_stripe_price_id === element.price.id
        ) {
          itemObj.quantity = element.quantity;
          itemObj.stripe_price_id =
            this.selectedSubscription.center_stripe_price_id;
          this.centerItem.push(itemObj);
        } else {
          const attachmentStorage = [
            ...JSON.parse(JSON.stringify(this.storage)),
          ];
          let attachment = attachmentStorage.filter((ele) => {
            ele.id = element.id;
            return ele.stripe_price_id == element.price.id;
          });
          this.planStorage = attachment[0];
          if (attachment.length > 0) {
            itemObj.quantity = 1;
            itemObj.stripe_price_id = attachment[0].stripe_price_id;
            this.attachmentItem.push(itemObj);
          }
        }
      }
    },
    getColor(status) {
      if (status?.toLowerCase() === "pending") return "#C35309";
      else if (status?.toLowerCase() === "inactive") return "#616161";
      else if (status?.toLowerCase() === "expired") return "#FA1405";
      else return "#1E853A";
    },
    onEditPaymantMethod() {
      this.editPaymantMethod = true;
    },
    onCancel() {
      this.editPaymantMethod = false;
    },
    changePlanDialog() {
      let subscription = this.subscriptions.filter((element) => {
        return (
          element.subscription_id ===
          this.$store.state.Company?.company?.subscription_id
        );
      });
      if (
        subscription[0].subscription_rank <
        this.selectedSubscription.subscription_rank
      )
        this.selectedSubscription = subscription[0];
      if (parseInt(this.$store.state.Company?.company?.users) > this.users)
        this.users = parseInt(this.$store.state.Company?.company?.users);
      this.isDownGradePlan = false;
    },
    async changePlan() {
      let subscription = this.subscriptions.filter((element) => {
        return element.subscription_id === this.companyInfo.subscription_id;
      });
      if (
        subscription[0].subscription_rank <
          this.selectedSubscription.subscription_rank &&
        this.companyUserRole == "admin"
      )
        this.isDownGradePlan = true;
      if (parseInt(this.selectedSubscription.price) == 0)
        this.companyCenters = [this.companyCenters[0]];
      if (
        parseInt(this.selectedSubscription.price) !== 0 &&
        this.stripeSubcription.items
      ) {
        const items = this.stripeSubcription.items?.data;
        let newItems = [];
        if (
          this.selectedSubscription.stripe_price_id &&
          this.selectedSubscription.stripe_price_id ==
            this.subscriptionItem[0].stripe_price_id
        ) {
          this.subscriptionItem[0].deleted = false;
          this.subscriptionItem = [this.subscriptionItem[0]];
        } else if (
          this.selectedSubscription.stripe_price_id !==
          this.subscriptionItem[0].stripe_price_id
        ) {
          if (this.subscriptionItem[0]) this.subscriptionItem[0].deleted = true;
          if (
            this.subscriptionItem.length <= 1 &&
            this.selectedSubscription.stripe_price_id
          )
            this.subscriptionItem.push({
              price: this.selectedSubscription.stripe_price_id,
              quantity: this.users,
            });
          else if (this.subscriptionItem.length > 1)
            this.subscriptionItem[1] = {
              price: this.selectedSubscription.stripe_price_id,
              quantity: this.users,
            };
        }
        if (this.centerItem[0])
          this.centerItem[0].quantity = this.companyCenters.length - 1;
        newItems = [
          ...(this.selectedSubscription.stripe_price_id !==
            this.subscriptionItem[0].stripe_price_id ||
          this.users != parseInt(this.companyInfo.users)
            ? JSON.parse(JSON.stringify(this.subscriptionItem))
            : []),
          ...JSON.parse(JSON.stringify(this.centerItem)),
          ...JSON.parse(JSON.stringify(this.attachmentItem)),
        ];
        for (let i = 0; i < newItems.length; i++) {
          delete newItems[i].stripe_price_id;
        }
        const CompanyStatus = await this.$store.dispatch("retrieveInvoice", {
          stripe_customer_id: this.companyInfo.stripe_customer_id,
          newItems: newItems,
          subscription_proration_behavior: "always_invoice",
          id: this.stripeSubcription.id,
        });
        if (!CompanyStatus.upcomingInvoice.next_payment_attempt)
          this.amountDue = CompanyStatus.upcomingInvoice.amount_due / 100;
        else this.amountDue = 0;
      } else {
        this.amountDue =
          parseInt(
            this.selectedSubscription.price
              ? this.selectedSubscription.price * 12 * this.users
              : 0
          ) +
          parseInt(
            this.selectedSubscription.base_price
              ? this.selectedSubscription.base_price
              : 0
          ) +
          parseInt(
            this.planStorage &&
              this.planStorage.price &&
              parseInt(this.selectedSubscription.price) > 0
              ? this.planStorage.price / 100
              : 0
          );
      }

      if (this.selectedSubscription.products.storage)
        this.storage = this.selectedSubscription.products.storage.map(
          (element) => {
            element.stripe_price_id = this.priceMap[element.id]?.id;
            element.price = this.priceMap[element.id]?.unit_amount;
            return element;
          }
        );
    },
    async changeUsers() {
      if (
        parseInt(this.$store.state.Company?.company?.users) > this.users &&
        this.companyUserRole == "admin"
      )
        this.isDownGradePlan = true;
      if (
        parseInt(this.selectedSubscription.price) !== 0 &&
        this.stripeSubcription.items
      ) {
        const items = this.stripeSubcription.items?.data;
        let newItems = [];
        if (this.centerItem[0])
          this.centerItem[0].quantity = this.companyCenters.length - 1;
        if (this.subscriptionItem.length > 1)
          this.subscriptionItem[1].quantity = this.users;
        else if (this.subscriptionItem.length == 1)
          this.subscriptionItem[0].quantity = this.users;
        newItems = [
          ...JSON.parse(JSON.stringify(this.subscriptionItem)),
          ...JSON.parse(JSON.stringify(this.centerItem)),
          ...JSON.parse(JSON.stringify(this.attachmentItem)),
        ];
        for (let i = 0; i < newItems.length; i++) {
          delete newItems[i].stripe_price_id;
        }

        const CompanyStatus = await this.$store.dispatch("retrieveInvoice", {
          stripe_customer_id: this.companyInfo.stripe_customer_id,
          newItems: newItems,
          subscription_proration_behavior: "always_invoice",
          id: this.stripeSubcription.id,
        });
        if (!CompanyStatus.upcomingInvoice.next_payment_attempt)
          this.amountDue = CompanyStatus.upcomingInvoice.amount_due / 100;
        else this.amountDue = 0;
      } else {
        this.amountDue =
          parseInt(
            this.selectedSubscription.price
              ? this.selectedSubscription.price * 12 * this.users
              : 0
          ) +
          parseInt(
            this.selectedSubscription.base_price
              ? this.selectedSubscription.base_price
              : 0
          ) +
          parseInt(
            this.planStorage &&
              this.planStorage.price &&
              parseInt(this.selectedSubscription.price) > 0
              ? this.planStorage.price / 100
              : 0
          );
      }
    },
    async changeAttachment() {
      if (
        this.planStorage &&
        parseInt(this.companyInfo.storage) >
          parseInt(this.planStorage.storage_limit) &&
        this.companyUserRole == "admin"
      ) {
        this.isDownGradePlan = true;
        if (
          this.attachmentItem[0] &&
          this.planStorage.stripe_price_id !==
            this.attachmentItem[0].stripe_price_id
        ) {
          let oldStorage = this.storage.filter((element) => {
            return (
              this.attachmentItem[0].stripe_price_id === element.stripe_price_id
            );
          });
          this.planStorage = Object.assign({}, oldStorage[0]);
        }
      }
      if (
        parseInt(this.selectedSubscription.price) !== 0 &&
        this.stripeSubcription.items
      ) {
        let newItems = [];
        if (
          this.attachmentItem[0] &&
          this.planStorage.stripe_price_id ==
            this.attachmentItem[0].stripe_price_id
        ) {
          this.attachmentItem[0].deleted = false;
          this.attachmentItem = [this.attachmentItem[0]];
        } else if (
          !this.attachmentItem[0] ||
          this.planStorage.stripe_price_id !==
            this.attachmentItem[0].stripe_price_id
        ) {
          if (this.attachmentItem[0]) this.attachmentItem[0].deleted = true;
          if (this.attachmentItem.length <= 1)
            this.attachmentItem.push({
              price: this.planStorage.stripe_price_id,
              quantity: 1,
            });
          else if (this.attachmentItem.length > 1)
            this.attachmentItem[1] = {
              price: this.planStorage.stripe_price_id,
              quantity: 1,
            };
        }
        if (this.centerItem[0])
          this.centerItem[0].quantity = this.companyCenters.length - 1;
        else {
          this.centerItem = [
            {
              stripe_price_id: this.selectedSubscription.center_stripe_price_id,
              price: this.selectedSubscription.center_stripe_price_id,
              quantity: this.companyCenters.length - 1,
            },
          ];
        }
        newItems = [
          ...JSON.parse(JSON.stringify(this.subscriptionItem)),
          ...JSON.parse(JSON.stringify(this.centerItem)),
          ...JSON.parse(JSON.stringify(this.attachmentItem)),
        ];
        for (let i = 0; i < newItems.length; i++) {
          delete newItems[i].stripe_price_id;
        }
        const CompanyStatus = await this.$store.dispatch("retrieveInvoice", {
          stripe_customer_id: this.companyInfo.stripe_customer_id,
          newItems: newItems,
          subscription_proration_behavior: "always_invoice",
          id: this.stripeSubcription.id,
        });
        if (!CompanyStatus.upcomingInvoice.next_payment_attempt)
          this.amountDue = CompanyStatus.upcomingInvoice.amount_due / 100;
        else this.amountDue = 0;
      } else {
        this.amountDue =
          parseInt(
            this.selectedSubscription.price
              ? this.selectedSubscription.price * 12 * this.users
              : 0
          ) +
          parseInt(
            this.selectedSubscription.base_price
              ? this.selectedSubscription.base_price
              : 0
          ) +
          parseInt(
            this.planStorage &&
              this.planStorage.price &&
              parseInt(this.selectedSubscription.price) > 0
              ? this.planStorage.price / 100
              : 0
          );
      }
    },
    async submit() {
      this.updating = true;
      let companyDetails = this.companyInfo;
      let subscription = this.subscriptions.filter((element) => {
        return element.name === this.selectedSubscription.name;
      });
      let payment = "";
      if (
        parseInt(this.selectedSubscription.price) !== 0 &&
        this.payment_method != "invoice" &&
        (!(this.paymentMethods && this.paymentMethods?.exp_month) ||
          this.editPaymantMethod)
      ) {
        payment = await this.stripe.createPaymentMethod({
          type: "card",
          card: this.paymentElement,
          billing_details: {
            name: companyDetails.name,
            email: companyDetails.email,
            address: {
              line1: companyDetails.address,
              postal_code: companyDetails.zip,
              city: companyDetails.city,
              state: companyDetails.state,
              country: "US",
            },
          },
        });
      }
      companyDetails.paymentMethod = payment;
      companyDetails.payment_method = this.payment_method;
      companyDetails.priceList = [];
      if (this.subscriptionItem.length > 0) {
        if (this.centerItem[0])
          this.centerItem[0].quantity = this.companyCenters.length - 1;
        else {
          this.centerItem = [
            {
              stripe_price_id: this.selectedSubscription.center_stripe_price_id,
              price: this.selectedSubscription.center_stripe_price_id,
              quantity: this.companyCenters.length - 1,
            },
          ];
        }
      }
      companyDetails.priceList = [
        ...JSON.parse(JSON.stringify(this.subscriptionItem)),
        ...JSON.parse(
          JSON.stringify(
            this.centerItem[0]?.quantity > 0 ? this.centerItem : []
          )
        ),
        ...JSON.parse(JSON.stringify(this.attachmentItem)),
      ];
      for (let i = 0; i < companyDetails.priceList.length; i++) {
        delete companyDetails.priceList[i].stripe_price_id;
      }
      if (companyDetails.priceList.length === 0) {
        if (this.selectedSubscription)
          companyDetails.priceList.push({
            price: this.selectedSubscription.stripe_price_id,
            quantity: this.users,
          });
        if (this.companyCenters.length > 1)
          companyDetails.priceList.push({
            price: this.selectedSubscription.center_stripe_price_id,
            quantity: this.companyCenters.length - 1,
          });
        if (this.planStorage && Object.keys(this.planStorage).length > 1)
          companyDetails.priceList.push({
            price: this.planStorage.stripe_price_id,
            quantity: 1,
          });
      }
      companyDetails.storage =
        (this.planStorage ? parseInt(this.planStorage.storage_limit) : 0) + 250;
      companyDetails.price = this.amountDue;
      companyDetails.contactusData =
        this.$store.state.Subscription.contactusData;
      companyDetails.paymentHistory = {
        subscription_id: this.selectedSubscription.subscription_id,
        final_price: this.amountDue,
      };
      companyDetails.type = this.companyCenters[0].excavator_type;
      companyDetails.centers = this.companyCenters;
      companyDetails.subscription_id = subscription[0]
        ? subscription[0].subscription_id
        : this.selectedSubscription.subscription_id;
      companyDetails.users = this.users;
      this.$store.commit("setCompanyData", companyDetails);
      const CompanyStatus = await this.$store.dispatch(
        "updateCompanySubscription",
        companyDetails
      );
      if (
        parseInt(this.selectedSubscription.price) !== 0 &&
        this.payment_method != "invoice" &&
        CompanyStatus[0].client_secret
      ) {
        const result = await this.stripe.confirmCardPayment(
          CompanyStatus[0].client_secret,
          {
            payment_method: {
              type: "card",
              card: this.paymentElement,
              billing_details: {
                name: companyDetails.name,
                email: companyDetails.email,
                address: {
                  line1: companyDetails.address,
                  postal_code: companyDetails.zip,
                  city: companyDetails.city,
                  state: companyDetails.state,
                  country: "US",
                },
              },
            },
          }
        );
      }
      await this.fetchUserData(true);
      const CompanyData = await this.$store.dispatch("getCompanyDataById", {
        companyId: this.companyInfo.company_id,
      });
      this.updatedMessage = true;
      this.updating = false;
      if (CompanyData.status !== "error") {
        this.companyInfo.centers = this.companyCenters;
        this.centerCount = this.companyCenters.length;
        if (this.companyUserRole == "admin")
          this.$store.commit("setCompanyData", CompanyData[0]);
        this.$nextTick(async () => {
          this.$emit("updateCompanyData", CompanyData[0]);
          await this.getStripeSubscriptionData();
          this.amountDue = 0;
        });
      }
    },
    getStorageText(item) {
      if (item < 1000) return item + " GB";
      else {
        return item / 1000 + " TB";
      }
    },
    onCenterChange() {
      this.excavatorType = null;
    },
    close() {
      this.onAddCenter = true;
      this.selectedCenter = null;
      this.excavatorType = null;
    },
    onAddCenterClicked() {
      this.onAddCenter = false;
    },
    async deleteCenter(value) {
      this.deleteInProgress = true;
      let tempCenter = this.companyCenters.filter((val) => {
        return !(
          val.center_id === value.center_id &&
          val.excavator_type === value.excavator_type
        );
      });
      this.companyCenters = tempCenter;
      this.onAddCenter = true;
      this.deleteInProgress = false;
      if (
        parseInt(this.selectedSubscription.price) !== 0 &&
        this.stripeSubcription.items
      ) {
        let newItems = [];
        if (this.centerItem[0])
          this.centerItem[0].quantity = this.companyCenters.length - 1;
        else {
          this.centerItem = [
            {
              stripe_price_id: this.selectedSubscription.center_stripe_price_id,
              price: this.selectedSubscription.center_stripe_price_id,
              quantity:
                this.companyCenters.length - 1 >= 0
                  ? this.companyCenters.length - 1
                  : 0,
            },
          ];
        }
        newItems = [
          ...JSON.parse(JSON.stringify(this.subscriptionItem)),
          ...JSON.parse(JSON.stringify(this.centerItem)),
          ...JSON.parse(JSON.stringify(this.attachmentItem)),
        ];
        for (let i = 0; i < newItems.length; i++) {
          delete newItems[i].stripe_price_id;
        }
        const CompanyStatus = await this.$store.dispatch("retrieveInvoice", {
          stripe_customer_id: this.companyInfo.stripe_customer_id,
          newItems: newItems,
          subscription_proration_behavior: "always_invoice",
          id: this.stripeSubcription.id,
        });
        if (!CompanyStatus.upcomingInvoice.next_payment_attempt)
          this.amountDue = CompanyStatus.upcomingInvoice.amount_due / 100;
        else this.amountDue = 0;
      } else {
        this.amountDue =
          parseInt(
            this.selectedSubscription.price
              ? this.selectedSubscription.price * 12 * this.users
              : 0
          ) +
          parseInt(
            this.selectedSubscription.base_price
              ? this.selectedSubscription.base_price
              : 0
          ) +
          parseInt(
            this.planStorage &&
              this.planStorage.price &&
              parseInt(this.selectedSubscription.price) > 0
              ? this.planStorage.price / 100
              : 0
          );
      }
    },
    async addCenter() {
      const centersObject = {};
      centersObject.center_id = this.selectedCenter;
      centersObject.excavator_type = this.excavatorType;
      centersObject.default = this.companyCenters.length === 0 ? true : false;
      let tempCallerTypes = this.centerData.filter((element, index) => {
        return element["center_id"] === this.selectedCenter;
      });
      let tempCenter = this.companyCenters.find((val) => {
        return (
          val.center_id === this.selectedCenter &&
          val.excavator_type === this.excavatorType
        );
      });
      if (!tempCenter) {
        centersObject.center_name = tempCallerTypes[0].center_name;
        centersObject.abbrev = tempCallerTypes[0].abbrev;
        centersObject.state = tempCallerTypes[0].state;
        this.companyCenters.push(centersObject);
      }
      this.selectedCenter = null;
      this.excavatorType = null;
      if (
        parseInt(this.selectedSubscription.price) !== 0 &&
        this.stripeSubcription.items
      ) {
        let newItems = [];
        if (this.centerItem[0])
          this.centerItem[0].quantity = this.companyCenters.length - 1;
        else {
          this.centerItem = [
            {
              stripe_price_id: this.selectedSubscription.center_stripe_price_id,
              price: this.selectedSubscription.center_stripe_price_id,
              quantity: this.companyCenters.length - 1,
            },
          ];
        }
        newItems = [
          ...JSON.parse(JSON.stringify(this.subscriptionItem)),
          ...JSON.parse(JSON.stringify(this.centerItem)),
          ...JSON.parse(JSON.stringify(this.attachmentItem)),
        ];
        for (let i = 0; i < newItems.length; i++) {
          delete newItems[i].stripe_price_id;
        }
        const CompanyStatus = await this.$store.dispatch("retrieveInvoice", {
          stripe_customer_id: this.companyInfo.stripe_customer_id,
          newItems: newItems,
          subscription_proration_behavior: "always_invoice",
          id: this.stripeSubcription.id,
        });
        if (!CompanyStatus.upcomingInvoice.next_payment_attempt)
          this.amountDue = CompanyStatus.upcomingInvoice.amount_due / 100;
        else this.amountDue = 0;
      } else {
        this.amountDue =
          parseInt(
            this.selectedSubscription.price
              ? this.selectedSubscription.price * 12 * this.users
              : 0
          ) +
          parseInt(
            this.selectedSubscription.base_price
              ? this.selectedSubscription.base_price
              : 0
          ) +
          parseInt(
            this.planStorage &&
              this.planStorage.price &&
              parseInt(this.selectedSubscription.price) > 0
              ? this.planStorage.price / 100
              : 0
          );
      }
      if (
        parseInt(this.selectedSubscription.price) === 0 &&
        this.companyCenters.length >= 1
      )
        this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
