<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card elevation="0" class="pb-10">
        <v-card-text class="pa-0 mb-2">
          <v-row justify="center">
            <img
              class="pt-7 pb-4"
              src="@/assets/images/logo.png"
              alt="Dig Logix Logo"
              style="width: 11%"
            />
          </v-row>
          <v-row
            justify="center"
            class="mx-auto"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-3 mt-4' : 'mb-2 mt-2'"
          >
            <span
              class="text-h5 font-weight-bold grey--text text--darken-4 mx-auto text-center"
              style="display: block"
            >Contact Us</span>
          </v-row>
          <v-card-text class="d-flex justify-center"
            >Contact Norfield today for guidance with selecting a service plan
            tailored to your companies needs.</v-card-text
          >
        </v-card-text>
        <v-card-text :class="$vuetify.breakpoint.mdAndUp ? 'px-6' : ''">
          <v-row justify="center">
            <v-col
              cols="12"
              lg="7"
              md="6"
              class="pl-3 company-info-form-container"
            >
              <v-row>
                <v-col cols="12" class="py-0">
                  <label>{{ $t("companyName") }}</label>
                  <v-text-field
                    v-model="companyName"
                    :error-messages="companyNameErrors"
                    outlined
                    required
                    dense
                    @input="$v.companyName.$touch()"
                    @blur="$v.companyName.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0">
                  <label>{{ $t("firstName") }}</label>
                  <v-text-field
                    v-model="firstName"
                    :error-messages="firstNameErrors"
                    outlined
                    required
                    dense
                    @input="$v.firstName.$touch()"
                    @blur="$v.firstName.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <label>{{ $t("lastName") }}</label>
                  <v-text-field
                    v-model="lastName"
                    :error-messages="lastNameErrors"
                    outlined
                    required
                    dense
                    @input="$v.lastName.$touch()"
                    @blur="$v.lastName.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <label>{{ $t("ContactEmail") }}</label>
                  <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    outlined
                    required
                    dense
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-1">
                <v-col cols="12" class="py-0">
                  <label>{{ $t("contactPhoneNumber") }}</label>
                  <v-text-field
                    v-model="phoneNumber"
                    :error-messages="phoneNumberErrors"
                    outlined
                    required
                    dense
                    @input="$v.phoneNumber.$touch()"
                    @blur="$v.phoneNumber.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="6" class="py-0">
                  <label>{{ $t("jobTitle") }}</label>
                  <v-text-field
                    v-model="jobTitle"
                    outlined
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <label>{{ $t("numberOfEmployees") }}</label>
                  <v-text-field
                    type="number"
                    v-model="companySize"
                    :error-messages="companySizeError"
                    outlined
                    required
                    dense
                    @input="$v.companySize.$touch()"
                    @blur="$v.companySize.$touch()"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col col="12" class="py-0">
                  <label>{{ $t("HowCanWeHelp") }}</label>
                  <v-textarea
                    rows="3"
                    v-model="needHelpText"
                    outlined
                    required
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row justify-center>
                <v-col col="10"></v-col>
                <v-col col="2" class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    outlined
                    @click="closeDialog"
                    class="text-none rounded-lg m-3 mx-4"
                    >Cancel</v-btn
                  >
                  <v-btn
                    :disabled="$v.$invalid"
                    color="primary"
                    class="text-none rounded-lg m-3"
                    @click="submitDetails"
                  >
                    {{ $t("submit") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import { required, email } from "vuelidate/lib/validators";
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
);
const size = helpers.regex("serial", /^\d+$/);
export default {
  name: "ContactUs",
  mixins: [validationMixin],
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    companyInformation: {
      type: Object,
      default: () => {},
    },
  },
  validations() {
    return {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      companyName: {
        required,
      },
      phoneNumber: { required, phone },
      email: { required, email },
      companySize: { required, size },
    };
  },
  data() {
    return {
      jobTitle: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      companyName: "",
      email: "",
      companySize: null,
      needHelpText: "",
      contactData: {},
      hideSignUp: true,
      step: 2,
    };
  },
  computed: {
    companyNameErrors() {
      const errors = [];
      if (!this.$v.companyName.$dirty) return errors;
      !this.$v.companyName.required && errors.push(this.$t("companyNameError"));
      return errors;
    },

    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("firstNameRequiredError"));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("lastNameRequiredError"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("companyEmailValidError"));
      !this.$v.email.required && errors.push(this.$t("emailRequiredError"));
      return errors;
    },
    companySizeError() {
      const errors = [];
      if (!this.$v.companySize.$dirty) return errors;
      !this.$v.companySize.required &&
        errors.push(this.$t("comapanySizeError"));
      !this.$v.companySize.size &&
        errors.push(this.$t("InvalidComapanySizeError"));
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      !this.$v.phoneNumber.required &&
        errors.push(this.$t("phoneRequiredError"));
      !this.$v.phoneNumber.phone &&
        errors.push(this.$t("contactPhoneValidError"));
      return errors;
    },
  },
  created() {
    this.companyName = this.companyInformation.name;
    this.phoneNumber = this.companyInformation.phone;
    this.email = this.companyInformation.email;
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    async submitDetails() {
      const {
        companyName,
        firstName,
        lastName,
        email,
        phoneNumber,
        companySize,
        needHelpText,
        jobTitle,
      } = this;
      let companyDetails = this.companyInformation;
      this.contactData = {
        companyName,
        firstName,
        lastName,
        email,
        phoneNumber,
        companySize,
        ...(needHelpText && { needHelpText }),
        ...(jobTitle && { jobTitle }),
      };
      companyDetails.paymentHistory = {
        subscription_id: "36212d34-2acb-4c41-a639-e9063273ccb2",
        final_price: 0,
      };

      companyDetails.contactusData = this.contactData;
      await this.$store.dispatch("sendQuote", companyDetails);
      this.$emit("sentQuote");
      this.$emit("changeContactUs", { disableContactUs: true });
    },
  },
};
</script>

<style lang="scss">
.container.fill-height {
  display: block !important;
}

.m-l-30 {
  margin-left: 30%;
}

.btn-margin {
  margin-top: 3%;
}

@media only screen and (max-width: 768px) {
  .container.fill-height {
    display: flex !important;
  }

  .btn-margin {
    margin-top: 6%;
  }
}
</style>
