var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card",
            { staticClass: "pb-10", attrs: { elevation: "0" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-0 mb-2" },
                [
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c("img", {
                      staticClass: "pt-7 pb-4",
                      staticStyle: { width: "11%" },
                      attrs: {
                        src: require("@/assets/images/logo.png"),
                        alt: "Dig Logix Logo",
                      },
                    }),
                  ]),
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-auto",
                      class: _vm.$vuetify.breakpoint.smAndDown
                        ? "mb-3 mt-4"
                        : "mb-2 mt-2",
                      attrs: { justify: "center" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-h5 font-weight-bold grey--text text--darken-4 mx-auto text-center",
                          staticStyle: { display: "block" },
                        },
                        [_vm._v("Contact Us")]
                      ),
                    ]
                  ),
                  _c("v-card-text", { staticClass: "d-flex justify-center" }, [
                    _vm._v(
                      "Contact Norfield today for guidance with selecting a service plan tailored to your companies needs."
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-text",
                { class: _vm.$vuetify.breakpoint.mdAndUp ? "px-6" : "" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-3 company-info-form-container",
                          attrs: { cols: "12", lg: "7", md: "6" },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("companyName"))),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      "error-messages": _vm.companyNameErrors,
                                      outlined: "",
                                      required: "",
                                      dense: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.companyName.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.companyName.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.companyName,
                                      callback: function ($$v) {
                                        _vm.companyName = $$v
                                      },
                                      expression: "companyName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "6" } },
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("firstName"))),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      "error-messages": _vm.firstNameErrors,
                                      outlined: "",
                                      required: "",
                                      dense: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.firstName.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.firstName.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.firstName,
                                      callback: function ($$v) {
                                        _vm.firstName = $$v
                                      },
                                      expression: "firstName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "6" } },
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("lastName"))),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      "error-messages": _vm.lastNameErrors,
                                      outlined: "",
                                      required: "",
                                      dense: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.lastName.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.lastName.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.lastName,
                                      callback: function ($$v) {
                                        _vm.lastName = $$v
                                      },
                                      expression: "lastName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("ContactEmail"))),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      "error-messages": _vm.emailErrors,
                                      outlined: "",
                                      required: "",
                                      dense: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.email.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.email.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-1" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("contactPhoneNumber"))
                                    ),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      "error-messages": _vm.phoneNumberErrors,
                                      outlined: "",
                                      required: "",
                                      dense: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.phoneNumber.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.phoneNumber.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.phoneNumber,
                                      callback: function ($$v) {
                                        _vm.phoneNumber = $$v
                                      },
                                      expression: "phoneNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "6" } },
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("jobTitle"))),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      required: "",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.jobTitle,
                                      callback: function ($$v) {
                                        _vm.jobTitle = $$v
                                      },
                                      expression: "jobTitle",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "6" } },
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("numberOfEmployees"))),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "number",
                                      "error-messages": _vm.companySizeError,
                                      outlined: "",
                                      required: "",
                                      dense: "",
                                      "hide-spin-buttons": "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.companySize.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.companySize.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.companySize,
                                      callback: function ($$v) {
                                        _vm.companySize = $$v
                                      },
                                      expression: "companySize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { col: "12" } },
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("HowCanWeHelp"))),
                                  ]),
                                  _c("v-textarea", {
                                    attrs: {
                                      rows: "3",
                                      outlined: "",
                                      required: "",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.needHelpText,
                                      callback: function ($$v) {
                                        _vm.needHelpText = $$v
                                      },
                                      expression: "needHelpText",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "justify-center": "" } },
                            [
                              _c("v-col", { attrs: { col: "10" } }),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-end",
                                  attrs: { col: "2" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "text-none rounded-lg m-3 mx-4",
                                      attrs: { color: "primary", outlined: "" },
                                      on: { click: _vm.closeDialog },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-none rounded-lg m-3",
                                      attrs: {
                                        disabled: _vm.$v.$invalid,
                                        color: "primary",
                                      },
                                      on: { click: _vm.submitDetails },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("submit")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }